@import 'scss/mixins.scss';

.angel-protocol {
	position: relative;
	padding: 60px 0;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	backdrop-filter: blur(50px);
	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		filter: blur(30px);
	}
	@include mobile {
		padding: 50px 0 30px 0;
		background: #f1f1f117;
	}
	&-wrapper {
		display: flex;
		align-items: center;
		@include mobile {
			flex-direction: column;
		}
	}
	.donated-amount {
		background-color: var(--black);
		border-radius: var(--b-rad-10);
		padding: 50px 35px;
		position: relative;
		@include mobile {
			padding: 50px 20px;
		}
		small,
		time {
			font-family: var(--ff-anek);
			font-size: var(--fs-15);
			position: absolute;
			color: var(--gray700);
		}
		small {
			left: 15px;
			top: 10px;
		}
		time {
			right: 15px;
			top: 10px;
		}
	}
	.donation-link {
		margin-left: 80px;
		margin-right: auto;
		@include mobile {
			margin-bottom: 30px;
			margin-left: unset;
			margin-right: unset;
			margin-top: 30px;
			text-align: center;
		}
		.section-heading {
			font-size: var(--fs-30);
			margin-bottom: 0;
		}
		.section-description {
			margin-bottom: 15px;
			margin-top: 15px;
		}
	}
	img.angel-protocol-logo {
		height: 180px;
		width: 400px;
		@include mobile {
			height: 140px;
			width: 300px;
		}
	}
}
