@import 'scss/mixins.scss';

.roadmap-items-card {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1295.06 411.38'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='647.53' y1='0' x2='647.53' y2='411.38' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23272424'/%3E%3Cstop offset='1' stop-color='%23171717'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect width='1295.06' height='411.38' rx='22.14' ry='22.14' style='fill:url(%23a)'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: var(--b-rad-22);
	padding: 35px;
	display: flex;
	@include mobile {
		padding: 20px;
		flex-direction: column-reverse;
	}
	&:not(:last-child) {
		margin-bottom: 40px;
	}
	&-content {
		max-width: 350px;
		margin-right: 100px;
		display: flex;
		position: relative;
		flex-direction: column;
		@include mobile {
			max-width: unset;
			margin-right: 0;
			margin-top: 40px;
			margin-bottom: 20px;
		}
		&-type-date {
			display: flex;
			align-items: center;
			justify-content: space-between;
			em {
				font-family: var(--ff-unison-light);
				font-size: var(--fs-15);
				color: var(--pink);
				text-transform: uppercase;
				@include mobile {
					font-size: var(--fs-14);
				}
			}
			time {
				font-family: var(--ff-nato);
				font-size: var(--fs-16);
				color: var(--gray600);
				@include mobile {
					font-size: var(--fs-14);
				}
			}
		}
		h2 {
			font-family: var(--ff-unison-bold);
			font-size: var(--fs-30);
			margin-bottom: auto;
			margin-top: 20px;
			color: var(--anis);
			line-height: var(--lh-primary);
			@include mobile {
				font-size: var(--fs-20);
				margin-bottom: 20px;
				margin-top: 10px;
			}
		}
		.section-description {
			color: var(--gray600);
			@include mobile {
				font-size: var(--fs-14);
			}
		}
		&::after {
			background-color: var(--black);
			height: 100%;
			width: 1px;
			content: '';
			position: absolute;
			right: -50px;
			top: 0;
			@include mobile {
				display: none;
			}
		}
	}
	&-image {
		width: 100%;
		img {
			border-radius: var(--b-rad-22);
			width: 100%;
			@include mobile {
				height: 295px;
				object-fit: cover;
			}
		}
	}
	&[data-direction='reverse'] {
		flex-direction: row-reverse;
		@include mobile {
			flex-direction: column-reverse;
		}

		.roadmap-items-card-content {
			margin-left: 100px;
			margin-right: unset;
			@include mobile {
				margin-left: 0;
			}
			&::after {
				left: -50px;
				right: unset;
			}
		}
	}
}
.migration-image {
	display: block;
	@include mobile {
		display: none;
	}
}
.migration-image-mobile {
	display: none;
	@include mobile {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
