@import 'scss/mixins.scss';

.card-header {
	font-size: var(--fs-14);
	margin-bottom: 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	em {
		font-family: var(--ff-unison-bold);
		color: var(--pink);
	}
	span {
		font-family: var(--ff-unison-light);
		color: var(--gray200);
		@include mobile {
			font-weight: 400;
			font-family: var(--ff-anek);
		}
	}
	&.title-success {
		em {
			color: var(--success);
		}
	}
}
