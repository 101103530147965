@import 'scss/mixins.scss';

.ant-modal {
	background-image: url('../../assets/img/modal-bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: var(--b-rad-15);
	height: 526px;
	width: 930px !important;
	margin: 60px auto;
	position: relative;
	@include mobile {
		background: linear-gradient(to bottom, #2a2727, #131313);
		border-radius: 0;
		height: 100%;
		width: 100% !important;
		margin: 0;
		// position: fixed;
		transform: unset;
		overflow: scroll;
	}
	&.ant-zoom-enter {
		animation-duration: 0.3s;
		transform: none;
		opacity: 0;
		user-select: none;
	}
	&.ant-zoom-appear {
		animation-duration: 0.3s;
		transform: none;
		opacity: 0;
		user-select: none;
	}
}

.ant-modal-mask {
	background-color: rgba(0, 0, 0, 0.45);
	height: 100%;
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: var(--zi-modal-mask);
	backdrop-filter: blur(5px);
	@include mobile {
		display: none;
	}
}
.ant-modal-mask-hidden {
	display: none;
}
.ant-modal-wrap {
	outline: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: var(--zi-modal-wrap);
}

.ant-modal-body {
	padding: 45px;
	@include mobile {
		padding: 20px;
	}
}

.ant-modal-content,
.ant-modal-body,
.modal-body {
	height: 100%;
}

.ant-modal-close,
.modal-close-button {
	background: transparent;
	border: 0;
	outline: 0;
	padding: 0;
	cursor: pointer;
	position: absolute;
	right: 8px;
	top: 8px;
	z-index: var(--zi-10);
	@include mobile {
		height: 25px;
		width: 25px;
	}
}

.ant-modal-open {
	overflow: hidden;
}

// Custom

.modal-body {
	display: flex;
	justify-content: space-between;
	@include mobile {
		margin-bottom: 30px;
	}
	.modal-label {
		font-family: var(--ff-unison-light);
		font-size: var(--fs-16);
		color: var(--pink);
		@include mobile {
			margin-bottom: 15px;
		}
	}
	.section-heading {
		font-size: var(--fs-30);
		margin: 20px 0;
		@include mobile {
			font-size: var(--fs-24);
			margin: 0 0 20px;
		}
	}
	.section-description {
		@include mobile {
			font-size: var(--fs-14);
		}
	}
	.modal-left {
		max-width: 400px;
		min-width: 330px;
		height: 100%;
		display: flex;
		position: relative;
		flex-direction: column;
		@include mobile {
			min-width: unset;
		}
		.modal-left-mobile-image {
			border-radius: var(--b-rad-15);
			margin-bottom: 20px;
			margin-top: 30px;
			display: none;
			@include mobile {
				display: block;
			}
		}
		&::after {
			background-color: var(--black);
			height: 100%;
			width: 1.5px;
			content: '';
			display: inline-block;
			position: absolute;
			right: -35px;
		}
	}
	.modal-right {
		height: 100%;
		.modal-image-wrapper {
			height: 100%;
			position: relative;
			@include mobile {
				display: none;
			}
			img {
				border-radius: var(--b-rad-15);
				height: 100%;
				max-width: 400px;
				object-fit: cover;
				@include mobile {
					width: 100%;
				}
			}
			.modal-image-text {
				left: 50%;
				position: absolute;
				top: 20px;
				z-index: var(--zi-15);
				text-align: center;
				transform: translateX(-50%);
			}
		}
	}
}

.modal-body.desktop {
	display: flex;
	@include mobile {
		display: none;
	}
}

.modal-body.mobile {
	display: none;
	.modal-label {
		display: inline-block;
		margin-bottom: 10px;
	}
	img.modal-main-image {
		width: 100%;
		margin-top: 30px;
		margin-bottom: 20px;
		border-radius: var(--b-rad-15);
	}
	@include mobile {
		display: block;
	}
}

// Modal Animations

@keyframes antZoomIn {
	0% {
		transform: scale(0.2);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}
@keyframes antZoomOut {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0.2);
		opacity: 0;
	}
}

@keyframes antZoomInMobile {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes antZoomOutMobile {
	0% {
	}
	100% {
		opacity: 0;
	}
}

.ant-zoom-enter {
	animation-duration: 0.3s;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-play-state: paused;
	transform: scale(0);
	animation-fill-mode: both;
	opacity: 0;
	&.ant-zoom-enter-active {
		animation-name: antZoomIn;
		animation-play-state: running;
		@include mobile {
			animation-name: antZoomInMobile;
			animation-duration: 0.2s;
		}
	}
}
.ant-zoom-appear {
	animation-duration: 0.3s;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-play-state: paused;
	transform: scale(0);
	animation-fill-mode: both;
	opacity: 0;
	@include mobile {
		animation: none;
	}
	&.ant-zoom-appear-active {
		animation-name: antZoomIn;
		animation-play-state: running;
		@include mobile {
			animation-name: antZoomInMobile;
			animation-duration: 0.2s;
		}
	}
}
.ant-zoom-leave {
	animation-duration: 0.3s;
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	animation-play-state: paused;
	animation-fill-mode: both;
	@include mobile {
		animation: none;
	}
	&.ant-zoom-leave-active {
		animation-name: antZoomOut;
		animation-play-state: running;
		pointer-events: none;
		@include mobile {
			animation-name: antZoomOutMobile;
			animation-duration: 0.2s;
		}
	}
}
.ant-zoom-enter-prepare {
	transform: none;
}
.ant-zoom-appear-prepare {
	transform: none;
}
