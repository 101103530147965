.rupture {
	.aside {
		.section-heading {
			font-size: var(--fs-30);
		}
	}

	.tessLink {
		color: #FF006C;
		text-decoration: underline;
		font-weight: bold;
	}
}
