@import 'scss/mixins.scss';

.loader {
	animation: spin 1s linear infinite;
	height: 10px;
	width: 10px;
	left: 50%;
	position: absolute;
	top: calc(25vh + 35px); // NOTE: 35px is half size of the spinner 70px
	transform: translate(-50%, -50%);
	@include mobile {
		top: 50%;
	}
}

@keyframes spin {
	0% {
		box-shadow: 0px -30px var(--pink), 10px -30px var(--pink), 20px -20px var(--pink), 30px -10px var(--pink), 30px 0px var(--pink), 30px 10px var(--pink), 20px 20px var(--pink), 10px 30px var(--pink), 0px 30px transparent, -10px 30px transparent,
			-20px 20px transparent, -30px 10px transparent, -30px 0px transparent, -30px -10px transparent, -20px -20px transparent, -10px -30px transparent;
	}
	6.25% {
		box-shadow: 0px -30px transparent, 10px -30px var(--pink), 20px -20px var(--pink), 30px -10px var(--pink), 30px 0px var(--pink), 30px 10px var(--pink), 20px 20px var(--pink), 10px 30px var(--pink), 0px 30px var(--pink), -10px 30px transparent,
			-20px 20px transparent, -30px 10px transparent, -30px 0px transparent, -30px -10px transparent, -20px -20px transparent, -10px -30px transparent;
	}
	12.5% {
		box-shadow: 0px -30px transparent, 10px -30px transparent, 20px -20px var(--pink), 30px -10px var(--pink), 30px 0px var(--pink), 30px 10px var(--pink), 20px 20px var(--pink), 10px 30px var(--pink), 0px 30px var(--pink), -10px 30px var(--pink),
			-20px 20px transparent, -30px 10px transparent, -30px 0px transparent, -30px -10px transparent, -20px -20px transparent, -10px -30px transparent;
	}
	18.75% {
		box-shadow: 0px -30px transparent, 10px -30px transparent, 20px -20px transparent, 30px -10px var(--pink), 30px 0px var(--pink), 30px 10px var(--pink), 20px 20px var(--pink), 10px 30px var(--pink), 0px 30px var(--pink), -10px 30px var(--pink),
			-20px 20px var(--pink), -30px 10px transparent, -30px 0px transparent, -30px -10px transparent, -20px -20px transparent, -10px -30px transparent;
	}
	25% {
		box-shadow: 0px -30px transparent, 10px -30px transparent, 20px -20px transparent, 30px -10px transparent, 30px 0px var(--pink), 30px 10px var(--pink), 20px 20px var(--pink), 10px 30px var(--pink), 0px 30px var(--pink), -10px 30px var(--pink),
			-20px 20px var(--pink), -30px 10px var(--pink), -30px 0px transparent, -30px -10px transparent, -20px -20px transparent, -10px -30px transparent;
	}
	31.25% {
		box-shadow: 0px -30px transparent, 10px -30px transparent, 20px -20px transparent, 30px -10px transparent, 30px 0px transparent, 30px 10px var(--pink), 20px 20px var(--pink), 10px 30px var(--pink), 0px 30px var(--pink), -10px 30px var(--pink),
			-20px 20px var(--pink), -30px 10px var(--pink), -30px 0px var(--pink), -30px -10px transparent, -20px -20px transparent, -10px -30px transparent;
	}
	37.5% {
		box-shadow: 0px -30px transparent, 10px -30px transparent, 20px -20px transparent, 30px -10px transparent, 30px 0px transparent, 30px 10px transparent, 20px 20px var(--pink), 10px 30px var(--pink), 0px 30px var(--pink), -10px 30px var(--pink),
			-20px 20px var(--pink), -30px 10px var(--pink), -30px 0px var(--pink), -30px -10px var(--pink), -20px -20px transparent, -10px -30px transparent;
	}
	43.75% {
		box-shadow: 0px -30px transparent, 10px -30px transparent, 20px -20px transparent, 30px -10px transparent, 30px 0px transparent, 30px 10px transparent, 20px 20px transparent, 10px 30px var(--pink), 0px 30px var(--pink), -10px 30px var(--pink),
			-20px 20px var(--pink), -30px 10px var(--pink), -30px 0px var(--pink), -30px -10px var(--pink), -20px -20px var(--pink), -10px -30px transparent;
	}
	50% {
		box-shadow: 0px -30px transparent, 10px -30px transparent, 20px -20px transparent, 30px -10px transparent, 30px 0px transparent, 30px 10px transparent, 20px 20px transparent, 10px 30px transparent, 0px 30px var(--pink), -10px 30px var(--pink),
			-20px 20px var(--pink), -30px 10px var(--pink), -30px 0px var(--pink), -30px -10px var(--pink), -20px -20px var(--pink), -10px -30px var(--pink);
	}
	56.25% {
		box-shadow: 0px -30px var(--pink), 10px -30px transparent, 20px -20px transparent, 30px -10px transparent, 30px 0px transparent, 30px 10px transparent, 20px 20px transparent, 10px 30px transparent, 0px 30px transparent, -10px 30px var(--pink),
			-20px 20px var(--pink), -30px 10px var(--pink), -30px 0px var(--pink), -30px -10px var(--pink), -20px -20px var(--pink), -10px -30px var(--pink);
	}
	62.5% {
		box-shadow: 0px -30px var(--pink), 10px -30px var(--pink), 20px -20px transparent, 30px -10px transparent, 30px 0px transparent, 30px 10px transparent, 20px 20px transparent, 10px 30px transparent, 0px 30px transparent, -10px 30px transparent,
			-20px 20px var(--pink), -30px 10px var(--pink), -30px 0px var(--pink), -30px -10px var(--pink), -20px -20px var(--pink), -10px -30px var(--pink);
	}
	68.75% {
		box-shadow: 0px -30px var(--pink), 10px -30px var(--pink), 20px -20px var(--pink), 30px -10px transparent, 30px 0px transparent, 30px 10px transparent, 20px 20px transparent, 10px 30px transparent, 0px 30px transparent, -10px 30px transparent,
			-20px 20px transparent, -30px 10px var(--pink), -30px 0px var(--pink), -30px -10px var(--pink), -20px -20px var(--pink), -10px -30px var(--pink);
	}
	75% {
		box-shadow: 0px -30px var(--pink), 10px -30px var(--pink), 20px -20px var(--pink), 30px -10px var(--pink), 30px 0px transparent, 30px 10px transparent, 20px 20px transparent, 10px 30px transparent, 0px 30px transparent, -10px 30px transparent,
			-20px 20px transparent, -30px 10px transparent, -30px 0px var(--pink), -30px -10px var(--pink), -20px -20px var(--pink), -10px -30px var(--pink);
	}
	81.25% {
		box-shadow: 0px -30px var(--pink), 10px -30px var(--pink), 20px -20px var(--pink), 30px -10px var(--pink), 30px 0px var(--pink), 30px 10px transparent, 20px 20px transparent, 10px 30px transparent, 0px 30px transparent, -10px 30px transparent,
			-20px 20px transparent, -30px 10px transparent, -30px 0px transparent, -30px -10px var(--pink), -20px -20px var(--pink), -10px -30px var(--pink);
	}
	87.5% {
		box-shadow: 0px -30px var(--pink), 10px -30px var(--pink), 20px -20px var(--pink), 30px -10px var(--pink), 30px 0px var(--pink), 30px 10px var(--pink), 20px 20px transparent, 10px 30px transparent, 0px 30px transparent, -10px 30px transparent,
			-20px 20px transparent, -30px 10px transparent, -30px 0px transparent, -30px -10px transparent, -20px -20px var(--pink), -10px -30px var(--pink);
	}
	93.75% {
		box-shadow: 0px -30px var(--pink), 10px -30px var(--pink), 20px -20px var(--pink), 30px -10px var(--pink), 30px 0px var(--pink), 30px 10px var(--pink), 20px 20px var(--pink), 10px 30px transparent, 0px 30px transparent, -10px 30px transparent,
			-20px 20px transparent, -30px 10px transparent, -30px 0px transparent, -30px -10px transparent, -20px -20px transparent, -10px -30px var(--pink);
	}
	100% {
		box-shadow: 0px -30px var(--pink), 10px -30px var(--pink), 20px -20px var(--pink), 30px -10px var(--pink), 30px 0px var(--pink), 30px 10px var(--pink), 20px 20px var(--pink), 10px 30px var(--pink), 0px 30px transparent, -10px 30px transparent,
			-20px 20px transparent, -30px 10px transparent, -30px 0px transparent, -30px -10px transparent, -20px -20px transparent, -10px -30px transparent;
	}
}
