@import 'scss/mixins.scss';

.section-heading {
	font-family: var(--ff-unison-bold);
	font-size: var(--fs-40);
	color: var(--anis);
	line-height: var(--lh-primary);
	@include mobile {
		font-size: var(--fs-26);
		line-height: var(--lh-secondary);
	}
}
