@import 'scss/mixins.scss';

.aside {
	margin-right: 50px;
	flex-basis: 360px;
	flex-grow: 0;
	flex-shrink: 0;
	@include mobile {
		margin-right: 0;
		flex-basis: unset;
		.section-heading {
			font-size: var(--fs-26);
			margin-bottom: 40px;
			text-align: center;
		}
	}
	.section-description {
		margin-bottom: 50px;
		margin-top: 25px;
		@include mobile {
			display: none;
		}
	}
	.link {
		width: 100%;
		& + .link,
		+ button {
			margin-top: 15px;
		}
	}
}
