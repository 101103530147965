@import 'scss/mixins.scss';

.logo {
	img {
		height: 50px;
		width: auto;
		// margin-right: 100px;
		@include mobile {
			margin-right: 0;
			height: 40px;
			width: 183px;
		}
	}
}
