@import 'scss/mixins.scss';

.pagination {
	display: none;
	gap: 5px;
	@include mobile {
		display: flex;
	}
	@media screen and (min-width: 1731px) {
		margin-left: -15.3rem;
		margin-bottom: -9rem;
	}
	@media screen and (min-width: 769px) and (max-width: 1515px) {
		margin-left: -3.7rem;
		margin-bottom: 0.5rem;
	}
	span {
		width: 8px;
		height: 8px;
		border: 1.5px solid var(--gray700);
		background-color: transparent;
		border-radius: var(--b-rad-round);
		transition: all 0.3s ease-in-out;
		&.active {
			width: 40px;
			height: 8px;
			border-radius: var(--b-rad-10);
			background-color: var(--gray700);
		}
	}
}
