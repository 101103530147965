@import 'scss/mixins.scss';

.the-bull-club {
	.poster {
		background-image: url('../../assets/img/bull-club-poster.webp');
		background-size: cover;
		height: 100vh;
		display: flex;
		transform: translateY(-80px); // Header height

		align-items: center;
		justify-content: center;
		position: relative;
		&::after {
			content: '';
			width: 100%;
			position: absolute;
			left: 0;
			bottom: 0;
			height: 487px;
			background: transparent linear-gradient(180deg, #00000000 0%, #101010f7 68%, #111111 100%) 0% 0% no-repeat padding-box;
			// background: blue;
		}
		@include mobile {
			height: 600px;
			transform: translateY(0);
			align-items: flex-start;
		}
		img {
			height: 280px;
			@include mobile {
				height: unset;
				width: 200px;
				margin-top: 60px;
			}
		}
	}
	.main-content {
		width: 100%;
		.the-bull-club-cards {
			display: grid;
			justify-content: space-between;
			grid-gap: 15px;
			grid-template-columns: repeat(3, 1fr);
			@include mobile {
				display: flex;
				gap: 30px;
				flex-direction: column;
			}
			.card {
				@include mobile {
					order: 1;
				}
				.card-actions {
					gap: 10px;
				}
				&.card-middle {
					@include mobile {
						background: #303030;
						font-size: var(--fs-14);
						order: 0;
						padding: 15px;
						flex-direction: row;
						align-items: center;
						gap: 15px;
						.label-icon {
							display: none;
						}
						.bottom-text {
							font-size: var(--fs-13);
						}
						img {
							width: 60px;
							order: 3;
							margin-left: auto;
						}
					}
				}
			}

			&:not(.without-slider) {
				@include mobile {
					display: none;
				}
			}
		}

		.the-bull-club-cards-slider {
			display: none;
			@include mobile {
				display: block;
			}
			&-header {
				margin-bottom: 40px;
				display: none;
				align-items: center;
				justify-content: space-between;
				@include mobile {
					display: flex;
				}
				strong {
					font-family: var(--ff-ocra);
					font-weight: 400;
					text-transform: uppercase;
				}
			}
		}
	}

	.aside {
		margin-bottom: 60px;
		.section-heading {
			@include mobile {
				font-size: 22px;
				margin-bottom: 60px;
				text-align: left;
			}
		}
		.section-description {
			font-size: var(--fs-14);
			display: block;
		}
	}
	&-aside-section-wrapper {
		display: flex;
		@include mobile {
			flex-direction: column;
		}
	}
}
