@import 'scss/mixins.scss';

strong.stroked-text {
	font-family: var(--ff-unison-bold);
	font-size: var(--fs-56);
	color: transparent;
	line-height: var(--lh-base);
	-webkit-text-fill-color: none;
	-webkit-text-stroke-color: var(--pink);
	-webkit-text-stroke-width: 2px;
	@include mobile {
		font-size: var(--fs-36);
	}
}
