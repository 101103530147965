@import 'scss/mixins.scss';

.main-slider {
	position: relative;
	.container-wrapper {
		position: absolute;
		left: 50%;
		height: 100%;
		transform: translateX(-50%);
		z-index: 100;
		pointer-events: none;
		@include mobile {
			max-width: unset;
			position: unset;
			transform: unset;
			left: unset;
		}
	}
	.control-button {
		background-color: transparent;
		background-repeat: no-repeat;
		background-size: contain;
		border: 0;
		height: 30px;
		width: 30px;
		cursor: pointer;
		position: absolute;
		top: 30%;
		z-index: var(--zi-10);
		transform: translateX(-50%);
		z-index: 100;
		pointer-events: auto !important;
		@include mobile {
			display: none;
			top: 50%;
		}
	}
	.prev-button {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3E%3Cpath d='m8.86 11.7 5.11-5.11c.44-.44 1.16-.44 1.61 0 .44.44.44 1.16 0 1.61l-4.31 4.31 4.31 4.31c.44.44.44 1.16 0 1.61-.44.44-1.16.44-1.61 0l-5.11-5.11c-.22-.22-.33-.51-.33-.8s.11-.58.33-.8Zm3.64-9.43C6.86 2.27 2.27 6.86 2.27 12.5S6.86 22.73 12.5 22.73s10.23-4.59 10.23-10.23S18.14 2.27 12.5 2.27ZM.98 7.63c.63-1.49 1.53-2.82 2.68-3.97A12.427 12.427 0 0 1 12.5 0c1.69 0 3.32.33 4.87.98 1.49.63 2.83 1.53 3.97 2.68s2.05 2.48 2.68 3.97c.65 1.54.98 3.18.98 4.87s-.33 3.32-.98 4.86c-.63 1.49-1.53 2.83-2.68 3.97s-2.48 2.05-3.97 2.68c-1.54.65-3.18.98-4.87.98s-3.32-.33-4.87-.98c-1.49-.63-2.83-1.53-3.97-2.68A12.427 12.427 0 0 1 0 12.5c0-1.68.33-3.33.98-4.87Z' style='fill:%23272424'/%3E%3C/svg%3E");
		left: -11.2rem;
		filter: brightness(1) invert(0.35);
	}

	.next-button {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 25 25'%3E%3Cpath d='m8.86 11.7 5.11-5.11c.44-.44 1.16-.44 1.61 0 .44.44.44 1.16 0 1.61l-4.31 4.31 4.31 4.31c.44.44.44 1.16 0 1.61-.44.44-1.16.44-1.61 0l-5.11-5.11c-.22-.22-.33-.51-.33-.8s.11-.58.33-.8Zm3.64-9.43C6.86 2.27 2.27 6.86 2.27 12.5S6.86 22.73 12.5 22.73s10.23-4.59 10.23-10.23S18.14 2.27 12.5 2.27ZM.98 7.63c.63-1.49 1.53-2.82 2.68-3.97A12.427 12.427 0 0 1 12.5 0c1.69 0 3.32.33 4.87.98 1.49.63 2.83 1.53 3.97 2.68s2.05 2.48 2.68 3.97c.65 1.54.98 3.18.98 4.87s-.33 3.32-.98 4.86c-.63 1.49-1.53 2.83-2.68 3.97s-2.48 2.05-3.97 2.68c-1.54.65-3.18.98-4.87.98s-3.32-.33-4.87-.98c-1.49-.63-2.83-1.53-3.97-2.68A12.427 12.427 0 0 1 0 12.5c0-1.68.33-3.33.98-4.87Z' style='fill:%23272424'/%3E%3C/svg%3E");
		right: -11.2rem;
		transform: rotate(180deg);
		filter: brightness(1) invert(0.35);
	}

	@media screen and (max-width: 1515px) {
		.prev-button {
			left: 0;
		}
		.next-button {
			right: 0;
		}
	}

	@media screen and (max-width: 1730px) and (min-width: 1516px) {
		.prev-button {
			left: -6.2rem;
		}
		.next-button {
			right: -6.2rem;
		}
	}

	.pagination {
		bottom: 560px;
		display: flex;
		left: 50px;
		position: absolute;
		z-index: var(--zi-10);
		@include mobile {
			bottom: 40px;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	.swiper-slide {
		position: relative;

		&::before {
			height: 500px;
			font-size: var(--fs-50);
			width: 50%;
			content: attr(data-index);
			left: 78%;
			position: absolute;
			top: 25%;
			transform: translate(-50%, -50%);
			@include mobile {
				top: 50%;
				height: 300px;
				top: 70%;
				width: 70%;
			}
		}
	}
}

// Homepage Image Container for Slide 1 (also Solana Logo Background Image SVG)

.homepage-image-container-bullclub {
	position: relative;
	z-index: 100;
	height: 1200px;
	background-color: rgba(7, 17, 47, 1);
	background-image: linear-gradient(0deg, rgba(7, 17, 47, 0.5), rgba(7, 17, 47, 0.5)), url('../../../assets/img/solana-sol-logo.svg');
	background-size: 1150px 1150px;
	background-repeat: no-repeat;
	@include mobile {
		background-size: 500px 550px;
		height: auto;
		background-position: center 80px;
		height: 600px;
		&::before {
			content: 'Chapter...' !important;
		}
		&::after {
			content: 'A new' !important;
			font-size: 17px !important;
			font-family: 'Unison Pro Bold';
			position: absolute;
			left: 50%;
			top: 5%;
			transform: translate(-50%);
		}
	}
	&::before {
		content: 'The Rebrand...';
		font-size: 32.53px;
		font-family: 'Unison Pro Bold';
		font-style: normal;
		font-weight: var(--fw-600);
		left: 56.3%;
		position: absolute;
		top: 36%;
		@include mobile {
			left: 50%;
			top: 10%;
			transform: translate(-50%);
		}
	}
}

// Homepage Image Container for Slide 2

.homepage-image-container-toxxxify {
	position: relative;
	z-index: 100;
	height: 1200px;
	@include mobile {
		background-size: 400px 400px;
		background-position: center 80px;
		height: 600px;
	}
}

// Homepage Image Container for Slide 3

.homepage-image-container-most-bullish {
	position: relative;
	z-index: 100;
	height: 1200px;
	@include mobile {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 600px;
	}
}


// Slide 1 Home Page Image

.homepage-image-bullclub {
	width: 50%;
	transform: translateY(-50px);
	@include mobile {
		height: 90%;
		width: 100%;
		margin-top: 110px;
		object-fit: cover;
	}
}

// Slide 2 Home Page Image

.homepage-image-toxxxify {
	width: 100%;
	@include mobile {
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

// Slide 3 Home Page Image

.homepage-image-most-bullish {
	width: 100%;
	@include mobile {
		display: none;
	}
}

.homepage-image-most-bullish-mobile {
	display: none;
	@include mobile {
		display: block;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

// Slide V2 Home Page Image
.homepage-image-container-v2 {
	position: relative;
	z-index: 100;
	top: -8em;
	height: 1200px;
	@include mobile {
		top: -3em;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 600px;
	}
}

.homepage-image-v2 {
	width: 100%;
	margin-top: -4em;
	@include mobile {
		display: block;
		margin-top: -1em;
		height: 100%;
		object-fit: cover;
	}
}

// The Bull Club 2.0 Logo Container

.svg-logo-container-v2 {
	position: absolute;
	top: 32%;
	left: 41.6%;
	@include mobile {
		object-fit: cover;
		left: 30%;
		top: 34%;
	}
	@media screen and (min-width: 769px) and (max-width: 1515px) {
		object-fit: cover;
		left: 39.7%;
		top: 24%;
	}
	@media screen and (max-width: 1730px) and (min-width: 1516px) {
		left: 40.5%;
		top: 28%;
	}
}

.svg-logo-v2 {
	width: 300px;
	height: 300px;
	@include mobile {
		width: 150px;
		height: 150px;
	}
}

.v2-button {
	position: absolute;
	box-shadow: 0px 1px 6px #00000080;
	top: 56%;
	left: 49.3%;
	min-height: 45px;
	min-width: 250px;
	justify-content: center;
	transform: translateX(-49.3%);
	@include mobile {
		top: 60%;
		left: 48%;
		width: 40%;
		min-width: 25px;
		display: flex;
		justify-content: center;
	}
	@media screen and (min-width: 769px) and (max-width: 1515px) {
		top: 48%;
		left: 49%;
	}
	@media screen and (max-width: 1730px) and (min-width: 1516px) {
		top: 51.3%;
		left: 49%;
	}
	span {
		font-size: 1.4em;
		@include mobile {
			font-size: 1.15em;
		}
	}
}

.v2-font2 {
	font-family: 'Unison Pro Light';
	font-size: 15px;
	color: #f2f7e4;
	@include mobile {
		font-size: 13px;
	}
}

.svg-text-container-v2 {
	position: absolute;
	top: 62.5%;
	left: -1%;
	width: 100%;
	text-align: center;
	@include mobile {
		position: absolute;
		top: 72.5%;
		left: 0;
		width: 100%;
		text-align: center;
	}
	@media screen and (min-width: 769px) and (max-width: 1515px) {
		top: 54%;
		left: -1em;
	}
	@media screen and (max-width: 1730px) and (min-width: 1516px) {
		top: 57%;
	}
}

.homepage-image-xlogo {
	position: absolute;
	top: 65.9%;
	left: 49%;
	width: 50px;
	text-align: center;
	transform: translateX(-49%);
	@include mobile {
		position: absolute;
		top: 78%;
		left: 48.2%;
		width: 15%;
		text-align: center;
	}
	@media screen and (min-width: 769px) and (max-width: 1515px) {
		top: 57.3%;
		left: 49%;
		width: 4%;
	}
	@media screen and (max-width: 1730px) and (min-width: 1516px) {
		top: 60%;
	}
}

// Homepage Slide 1 Button

.homepage-image1-button {
	position: absolute;
	left: 52%;
	top: 46%;
	background: transparent linear-gradient(180deg, #00cfa0 0%, #00c3cb 100%, #c461da 100%) 0% 0% no-repeat padding-box;
}

// The Bull Club Logo container

.svg-logo-container-bullclub {
	position: absolute;
	top: 12%;
	left: 58%;
	@include mobile {
		display: none;
	}
}

// Toxxxify Logo container

.svg-logo-container-toxxxify {
	position: absolute;
	top: -10%;
	left: 50%;
	transform: translate(-50%);
	@include mobile {
		top: 0%;
		left: 50%;
	}
}

.svg-logo-container-toxxxify2 {
	position: absolute;
	top: 25%;
	left: 53%;
	transform: translate(-50%);
	@include mobile {
		top: 35%;
	}
}

.svg-logo-container-most-bullish,
.svg-logo-container-portal {
	position: absolute;
	top: 26%;
	left: 25%;
	width: 100%;
	text-align: center;
	@include mobile {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		text-align: center;
	}
}

.svg-logo-container-most-bullish.svg-logo-container-portal {
	top: 23%;
	right: 23%;
	left: unset;
	@include mobile {
		top: 50%;
		left: 0;
		width: 100%;
		text-align: center;
	}
}

// The Bull Club Logo Image

.svg-logo-bullclub {
	width: 300px;
	height: 300px;
}

// Toxxxify Logo Image

.svg-logo-toxxxify {
	width: 800px;
	height: 800px;
	@include mobile {
		width: 380px;
		height: 400px;
	}
}

// Toxxxify Logo Image 2

.svg-logo-toxxxify2 {
	width: 90%;
	height: 350px;
	@include mobile {
		width: 350px;
		height: 250px;
	}
}

// Bullclub Bbutton

.bullclub-button {
	background: transparent linear-gradient(180deg, #00cfa0 0%, #00c3cb 100%, #c461da 100%);
	border: transparent;
	position: absolute;
	left: 60.5%;
	top: 42%;
	padding: 20px 30px;
	&::before {
		background: transparent linear-gradient(180deg, #00cfa0 0%, #00c3cb 100%, #c461da 100%);
		border-color: transparent;
	}
	@include mobile {
		width: 230px;
		display: flex;
		justify-content: center;
		left: 50%;
		transform: translate(-50%);
		top: 70%;
		position: absolute;
	}
}

// Toxxxify Button

.toxxxify-button {
	background: transparent linear-gradient(180deg, #00ff99 0%, #00bc71 100%) 0% 0% no-repeat padding-box;
	border: transparent;
	position: absolute;
	left: 64%;
	top: 37%;
	padding: 20px 30px;
	color: black;
	font-weight: bold;
	&::before {
		background: transparent linear-gradient(180deg, #00ff99 0%, #00bc71 100%) 0% 0% no-repeat padding-box;
		border-color: transparent;
		z-index: var(--zi--5);
		transition: all 0.7s cubic-bezier(0.77, 0, 0.175, 1);
		opacity: 1;
	}

	@include mobile {
		width: 230px;
		display: flex;
		justify-content: center;
		left: 50%;
		transform: translate(-50%);
		top: 80%;
		position: absolute;
	}
}

// Most Bullish Button

.most-bullish-button,
.portal-button {
	position: absolute;
	top: 42.5%;
	left: 74.5%;
	transform: translateX(-50%);
	@include mobile {
		top: 73%;
		left: 50%;
		width: 60%;
		display: flex;
		justify-content: center;
	}
}

.most-bullish-button.portal-button {
	display: inline-block;
	position: unset;
	transform: unset;
	margin-top: 40px;
	border: transparent;
	padding: 13px 30px;
	height: 40px;
	background: transparent linear-gradient(180deg, #7f00cf 0%, #a654d1 0%, #00c3cb 100%, #c461da 100%) 0% 0% no-repeat padding-box;
}

// Most Bullish P Tags

.most-bullish-font {
	font-family: 'Unison Pro Bold';
	font-size: 36px;
	color: #f2f7e4;
	@include mobile {
		font-size: 22px;
	}
}

.most-bullish-font2 {
	font-family: 'Unison Pro Light';
	font-size: 70px;
	color: #f2f7e4;
	@include mobile {
		font-size: 50px;
	}
}

// omnichain section

.homepage-image-container-omnichain {
	position: relative;
	z-index: 100;
	top: -4em;
	height: 1200px;
	@include mobile {
		top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 600px;
	}
}

.homepage-image-omnichain {
	width: 100%;
	@include mobile {
		display: none;
	}
}

.homepage-image-omnichain-mobile {
	display: none;
	@include mobile {
		display: block;
		margin-top: -2em;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
}

.omnichain-font {
	font-family: 'Unison Pro Bold';
	font-size: 46px;
	color: #f2f7e4;
	@include mobile {
		font-size: 22px;
	}
}

.omnichain-font2 {
	font-family: 'Unison Pro Bold';
	font-size: 70px;
	color: #f2f7e4;
	@include mobile {
		font-size: 38px;
	}
}

.omnichain-font3 {
	font-family: 'Unison Pro Bold';
	font-size: 46px;
	color: #FF006C;
	@include mobile {
		font-size: 22px;
	}
}

.svg-logo-container-omnichain {
	position: absolute;
	top: 9.5%;
	left: 0;
	width: 100%;
	text-align: center;
	@include mobile {
		position: absolute;
		top: 7%;
		left: 0;
		width: 100%;
		text-align: center;
	}
}

.omnichain-button {
	position: absolute;
	box-shadow: 0px 1px 6px #00000080;
	top: 57%;
	left: 50%;
	min-height: 45px;
	min-width: 250px;
	justify-content: center;
	transform: translateX(-50%);
	@include mobile {
		top: 32%;
		left: 50%;
		width: 40%;
		display: flex;
		justify-content: center;
	}
	@media screen and (min-width: 769px) and (max-width: 1515px) {
		top: 48%;
	}
	span {
		font-size: 1.2em;
	}
}

.homepage-image-omnilogos {
	position: absolute;
	top: 65%;
	left: 50%;
	width: 380px;
	text-align: center;
	transform: translateX(-50%);
	@include mobile {
		position: absolute;
		top: 80%;
		left: 50%;
		width: 68%;
		text-align: center;
	}
	@media screen and (min-width: 769px) and (max-width: 1515px) {
		top: 55%;
	}
}