@import 'scss/mixins.scss';

.core-team {
	padding: 180px 0 120px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	@include mobile {
		padding: 30px 0;
		flex-direction: column;
		align-items: center;
	}

	&-members {
		position: relative;
		display: flex;
		// justify-content: space-between;
		gap: 15px;
		// grid-column-gap: 15px;
		// grid-row-gap: 30px;
		// grid-template-columns: repeat(4, 218px);
		// justify-content: center;
		// max-width: fit-content;
		justify-content: center;
		@media (max-width: 1440px) {
			grid-template-columns: repeat(3, 218px);
		}
		@include mobile {
			display: none;
		}
	}
	.section-header {
		position: absolute;
		top: -100px;
		left: 0;
		width: 100%;
		.section-heading {
			text-align: center;

			@include mobile {
				font-size: var(--fs-18);
			}
		}
		@include mobile {
			position: relative;
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			width: 100%;
			padding: 30px 0;
			top: 0;
		}
	}

	.members-slider {
		display: none;
		@include mobile {
			display: block;
			max-width: 100%;
		}
	}
}

.core-team-container {
	position: relative;
	@include mobile {
		width: 100%;
	}
}
