.tooltip.tippy-box {
	background-color: #1e1e1e80;
	border-radius: var(--b-rad-12);
	font-family: var(--ff-ocra);
	position: relative;
	text-align: center;
	text-transform: uppercase;
	.tippy-content {
		background-color: #1e1e1e80;
		border: 2px solid var(--white);
		border-radius: var(--b-rad-12);
		padding: 12px 20px;
	}
	.tippy-svg-arrow {
		position: relative;
		top: -28px;
		.arrow {
			height: 23px;
			width: 23px;
			&::before {
				border: 14px solid transparent;
				border-bottom-color: var(--white);
				height: 0;
				width: 0;
				content: '';
				display: block;
				left: 0;
				position: absolute;
				top: 0;
			}
			&::after {
				border: 11px solid transparent;
				border-bottom-color: rgb(27 27 27);
				height: 0;
				width: 0;
				content: '';
				display: block;
				left: 3px;
				position: absolute;
				top: 6px;
			}
		}
	}
}
