@import 'scss/mixins.scss';

.not-found-container {
	max-height: 100vh;
	width: 100%;
	position: relative;
	&::before {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		height: 100%;
		content: '';
		background: transparent;
		backdrop-filter: blur(5px);
	}
}

.image-404 {
	height: 100%;
	width: 100%;
	@include mobile {
		height: 600px;
		object-fit: cover;
		width: 100%;
	}
}

.not-found-inner-div {
	position: absolute;
	top: 30%;
	left: 50%;
	width: 100%;
	transform: translate(-50%);
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	z-index: 2;
	height: 170px;
	@include mobile {
		top: 35%;
		height: 120px;
	}
}

.not-found-font {
	font-family: 'Unison Pro Bold', sans-serif;
	font-size: 40px;
	text-align: center;
	@include mobile {
		font-size: 25px;
		width: 100%;
	}
}
