@import 'scss/mixins.scss';

.header {
	background-color: #1a1a1a;
	padding: 20px;
	position: relative;
	z-index: var(--zi-header);
	.logo {
		display: block;
		margin: 0 auto;
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.container-wrapper.extended {
		max-width: 90%;
	}
	&-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&.blur {
		background-color: rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(15px);
	}
	nav {
		transition: all 0.4s ease-in-out;
		.menu-close-button {
			background-color: transparent;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23111' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			border: 0;
			height: 30px;
			width: 30px;
			margin-bottom: 30px;
			margin-top: 30px;
			padding: 0;
			display: none;
		}
		ul {
			display: flex;
			gap: 30px;
			@media (max-width: 1330px) {
				gap: 2px;
			}
			@include reset-list;
			.link {
				position: relative;
				@media (max-width: 1330px) {
					font-size: var(--fs-10);
				}
				&::before {
					background-color: var(--pink);
					height: 2px;
					width: 100%;
					content: '';
					bottom: 0;
					display: block;
					left: 0;
					position: absolute;
					transform: scaleX(0);
					transition: transform 0.3s ease;
				}
				&:hover,
				&:focus {
					&::before {
						transform: scaleX(1);
					}
				}
			}
		}
		.connect-wallet-button {
			display: none;
		}
	}

	.main-ctas {
		display: flex;
		button:first-child {
			margin-right: 10px;
		}
	}
	.burger,
	.wallet {
		background-color: transparent;
		border: 0;
		height: 30px;
		width: 30px;
		padding: 0;
		display: none;
	}
	.burger {
		cursor: pointer;
		position: relative;
		div {
			height: 12px;
			width: 22px;
			margin: auto;
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}
		span {
			background-color: var(--white);
			border-radius: var(--b-rad-1);
			height: 2px;
			width: 100%;
			display: block;
			position: absolute;
			transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
			&:first-of-type {
				top: 0;
			}
			&:last-of-type {
				bottom: 0;
			}
		}
		&.active {
			span {
				&:first-of-type {
					top: 5px;
					transform: rotate(45deg);
				}
				&:last-of-type {
					bottom: 5px;
					transform: rotate(-45deg);
				}
			}
		}
	}
	.wallet {
		background-image: url("data:image/svg+xml,%3Csvg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.42 11V5c0-.17.07-.34.19-.45.12-.12.28-.19.44-.19h9.32c.16 0 .32.07.43.18.12.12.19.29.19.46v1.26h-1.4c-.29 0-.56.12-.77.32-.19.2-.3.47-.31.75v1.32c0 .28.12.55.32.75.2.2.48.31.76.31h1.4V11c0 .17-.07.33-.19.45s-.28.19-.45.19H3.06c-.17 0-.33-.07-.45-.19a.636.636 0 0 1-.19-.45Zm.63-8.1h8.53v-.95a.827.827 0 0 0-.21-.39.334.334 0 0 0-.25-.12h-.07L3.19 2.87h-.01l-.13.03Zm12.3 3.77c.15.19.23.42.23.67v1.31c0 .28-.12.55-.32.75-.2.19-.47.31-.74.31H14.42V11a2.065 2.065 0 0 1-2.02 2.07H3.09c-.54 0-1.06-.22-1.43-.6-.42-.37-.67-.89-.69-1.45V3.55c0-.52.21-1.01.57-1.36.38-.36.86-.6 1.37-.68L10.83 0h.27c.48.01.93.21 1.26.55.3.32.51.71.6 1.14.01.03.01.06.01.09v1.25c.32.09.61.27.85.51.39.38.61.91.61 1.46v1.26H14.53c.28 0 .55.12.75.32.02.03.05.06.07.09Zm-3.37 1.06h2.18v.51h-2.18v-.51Z' fill='%23fff'/%3E%3C/svg%3E");
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}
}

.select__input {
	color: transparent !important;
}

@include mobile {
	.header {
		padding: 20px 0;
		nav {
			background-color: #f3f7e4;
			height: 100vh;
			width: 80vw;
			padding: 0 20px;
			left: 0;
			position: fixed;
			top: 0;
			transform: translateX(-100%);
			.menu-close-button {
				display: block;
			}
			ul {
				flex-direction: column;
				li {
					border-top: 1px solid var(--gray500);
					margin-left: -20px;
					margin-right: -20px !important;
					padding: 0 20px;
					&:last-child {
						border-bottom: 1px solid var(--gray500);
					}
				}
				.link {
					width: 100%;
					font-family: var(--ff-anek);
					font-size: var(--fs-16);
					font-weight: 500;
					padding: 20px 0;
					display: block;
					color: var(--black);
					&::before {
						display: none;
					}
				}
			}
			.connect-wallet-button {
				width: 100%;
				margin: 30px auto 0;
				display: block;
			}

			&.active {
				transform: translateX(0);
			}
		}
		.main-ctas {
			display: none;
		}
		.burger,
		.wallet {
			display: block;
		}
	}
}
