.filter-tab-buttons {
	display: flex;
	button {
		flex: 1 1 0px;
		padding: 0;
		background-color: #1e1e1e;
		border: 0;
		border-radius: var(--b-rad-5);
		color: var(--white);
		font-family: var(--ff-ocra);
		cursor: pointer;
		text-transform: uppercase;
		&.active {
			background-color: var(--pink);
			box-shadow: 1px 1px 5px 1px #1e1e1e;
			transform: scale(1.1);
			transform: scale(1.025);
		}
		span {
			display: block;
			padding: 12px 10px;
		}
	}
}
