@import 'scss/mixins.scss';

.statistics {
	// max-width: 1440px;
	max-width: 1200px;
	margin: 0 auto -450px;
	transform: translateY(-490px);
	margin-top: -6em;
	@include mobile {
		max-width: unset;
		margin: 0 auto -210px;
		padding: 0 20px;
		transform: translateY(-330px);
	}
	&-label {
		font-family: var(--ff-nato);
		font-size: var(--fs-16);
		bottom: -30px;
		display: flex;
		left: 0;
		position: relative;
		color: var(--white);
		align-items: center;
		@include mobile {
			display: none;
		}
		&::before {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath d='M9.09 8.6V1.87c-2.62.29-4.87 1.83-6.15 4L9.09 8.6ZM1.82 10c0 1.93.67 3.7 1.79 5.1l4.81-4.81L2.2 7.53c-.25.78-.38 1.61-.38 2.47ZM10 18.18c4.51 0 8.18-3.67 8.18-8.18 0-4.2-3.19-7.68-7.27-8.13v8.19c-.01.17-.07.33-.17.47-.03.05-.06.09-.1.13L4.9 16.4a8.152 8.152 0 0 0 5.1 1.79Zm-3.89 1.03c-1.19-.5-2.26-1.22-3.18-2.14S1.29 15.08.79 13.89C.27 12.66 0 11.35 0 10s.26-2.66.79-3.89c.5-1.19 1.22-2.26 2.14-3.18.92-.92 1.99-1.64 3.18-2.14C7.34.27 8.65 0 10 0s2.66.26 3.89.79c1.19.5 2.26 1.22 3.18 2.14.92.92 1.64 1.99 2.14 3.18.52 1.23.79 2.54.79 3.89s-.26 2.66-.79 3.89a9.904 9.904 0 0 1-2.14 3.18c-.92.92-1.99 1.64-3.18 2.14-1.23.52-2.54.79-3.89.79s-2.66-.26-3.89-.79Z' style='fill:%23fff;opacity:.2'/%3E%3C/svg%3E");
			background-size: contain;
			height: 20px;
			width: 20px;
			content: '';
			margin-right: 10px;
			display: inline-block;
		}
	}
	.statistics-cards {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		@include mobile {
			justify-content: center;
		}
		.statistics-card {
			@include mobile {
				display: none;
			}

			&.main-card {
				@include mobile {
					display: block;
				}
				.card-actions {
					justify-content: space-between;
				}
				.image-wrapper {
					position: relative;

					.loader {
						position: absolute;
						left: 50%;
						top: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}
		}
	}
	.statistics-cards-slider {
		border-bottom: 1.5px solid var(--gray700);
		border-top: 1.5px solid var(--gray700);
		margin-top: 60px;
		padding-bottom: 55px;
		display: none;
		@include mobile {
			display: block;
		}
		&-header {
			margin-bottom: 40px;
			margin-top: 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.statistics-label {
				display: flex;
				position: unset;
			}
		}
	}
}
