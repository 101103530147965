@import 'scss/mixins.scss';

.scrollable-bulls {
	overflow-x: hidden;
	width: 100%;
	margin-bottom: 120px;
	margin-top: 360px;
	display: flex;
	flex-direction: column;
	@include mobile {
		margin-bottom: 60px;
		margin-top: 60px;
	}
	@media screen and (min-width: 769px) and (max-width: 1515px) {
		margin-top: 250px;
	}
	.image-wrapper {
		// Magical numbers
		width: calc(12.875em * 40);
		display: flex;
		flex-wrap: nowrap;
		@include mobile {
			width: calc(12.875em * 20);
		}
		&.right {
			align-self: flex-end;
		}
		img {
			width: 200px;
			height: 200px;
			@include mobile {
				width: 100px;
				height: 100px;
			}
		}
	}
}
