@import 'scss/mixins.scss';

.link {
	font-family: var(--ff-unison-light);
	font-size: var(--fs-12);
	padding: 2px 0 4px;
	position: relative;
	color: var(--white);
	text-decoration: none;
	transition: color 0.3s ease;
	&:hover,
	&:focus {
		outline: none;
		color: var(--pink);
	}
	&.active {
		font-family: var(--ff-unison-bold);
		color: var(--pink);
	}
	&[data-variant='button'],
	&[data-variant='button-outlined'] {
		border-radius: var(--b-rad-5);
		font-family: var(--ff-ocra);
		font-size: var(--fs-14);
		padding: 13px;
		display: inline-block;
		text-align: center;
		text-transform: uppercase;
	}
	&[data-variant='button'] {
		background: transparent linear-gradient(180deg, #ff006c 0%, #b9004e 100%) 0% 0%;
		&:hover,
		&:focus {
			color: var(--white);
		}
	}
	&[data-variant='button-outlined'] {
		border: 1px solid var(--pink);
		padding: 11px 25px;
		@include mobile {
			padding: 8px 18px;
		}
	}
	&[data-size='medium'] {
		min-width: 150px;
	}
}
