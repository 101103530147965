@import 'scss/mixins.scss';

.messages {
	margin-bottom: 150px;
	text-align: center;
	@include mobile {
		margin-bottom: 120px;
	}
	em {
		font-family: var(--ff-anek);
		font-size: var(--fs-20);
		font-weight: var(--fw-600);
		color: var(--pink);
	}
	.section-heading {
		margin-bottom: 30px;
		margin-top: 30px;
	}
	.marketplace-links {
		margin-top: 30px;
		display: flex;
		justify-content: center;
		gap: 15px;
		@include mobile {
			align-items: center;
			flex-direction: column;
			.link {
				width: 200px;
			}
		}
	}
	button {
		margin: 0 auto;
	}
}
