@import 'scss/mixins.scss';

.bull-club {
	padding: 120px 0 120px 0;
	position: relative;
	@include mobile {
		padding: 60px 0;
	}
	&::before {
		background-image: url('../../../assets/img/blur-background.png');
		background-repeat: no-repeat;
		background-size: cover;
		height: 730px;
		width: 100%;
		content: '';
		bottom: 25%;
		position: absolute;
		z-index: var(--zi--1);
	}
	&-wrapper {
		display: flex;
		gap: 120px;
		& > div {
			flex-basis: 50%;
			flex-grow: 1;
		}
		@include mobile {
			flex-direction: column-reverse;
			gap: 0;
		}
	}
	&-content {
		font-family: var(--ff-nato);
		@include mobile {
			background: linear-gradient(to bottom, #2a2727, #131313);
			border-top-left-radius: 25px;
			border-top-right-radius: 25px;
			margin-bottom: -20px;
			margin-left: -20px;
			margin-right: -20px;
			padding: 40px 30px 0 30px;
			position: relative;
			z-index: var(--zi-5);
			transform: translateY(-20px);
		}
		strong {
			margin-bottom: 18px;
			display: block;
			color: #ff006c;
		}
		.section-description {
			margin-bottom: 30px;
			margin-top: 25px;
		}
		.link {
			@include mobile {
				width: 50%;
			}
		}
	}
	&-poster {
		position: relative;
		border-radius: var(--b-rad-15);
		max-height: 393px;
		max-width: 746px;
		@include mobile {
			margin: 0 0 40px 0;
			border-radius: 0;
			object-fit: cover;
		}
	}

	.read-blog-button {
		@include mobile {
			display: flex;
			width: 100%;
			justify-content: center;
		}
	}
}
