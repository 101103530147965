.card {
	background-image: url('../../assets/img/the-bull-club/card-bg.jpg');
	background-size: cover;
	border-radius: var(--b-rad-15);
	padding: 20px;
	img {
		border-radius: var(--b-rad-10);
		width: 100%;
	}
	.card-actions-title {
		width: 100%;
		font-size: var(--fs-14);
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		span {
			font-family: var(--ff-anek);
			font-weight: var(--fw-600);
		}
		em {
			font-family: var(--ff-unison-light);
			opacity: 0.6;
			&::before {
				content: '#';
			}
		}
	}
	.card-actions {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
	}

	&[data-variant='primary'] {
		background-image: url('../../assets/img/the-bull-club/card-primary-bg.jpg');
	}
}
