@import 'scss/mixins.scss';

.ant-modal.rupture-modal,
.ant-modal.bull-swap-modal {
	.modal-left {
		.section-heading {
			margin-bottom: auto;
			@include mobile {
				margin-bottom: 0;
			}
		}
	}
	.upgrade-bull-info {
		border-top: 1px solid var(--gray300);
		padding-bottom: 30px;
		padding-top: 12px;
		@include mobile {
			padding-bottom: 15px;
			padding-top: 15px;
			display: flex;
			align-items: center;
			gap: 15px;
		}
		p {
			font-family: var(--ff-nato);
			font-size: var(--fs-13);
			color: var(--gray300);
			@include mobile {
				font-size: var(--fs-12);
			}
		}
		h2 {
			font-family: var(--ff-unison-bold);
			font-size: var(--fs-16);
			margin-bottom: 4px;
			margin-top: 12px;
			@include mobile {
				font-size: var(--fs-14);
			}
		}
		em {
			font-family: var(--ff-anek);
			font-size: var(--fs-13);
			color: var(--pink);
			text-transform: uppercase;
			@include mobile {
				font-size: var(--fs-12);
			}
		}
		img {
			border-radius: 5px;
			height: 50px;
			width: 50px;
			margin: 0;
		}
	}
	button {
		margin-bottom: 30px;
		display: block;
	}
}
