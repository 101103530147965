.card-middle {
	padding: 30px;
	display: flex;
	flex-direction: column;
	.card-middle-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		em {
			font-family: var(--ff-unison-light);
		}
		.label-icon {
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.72 15'%3E%3Cpath d='M.21 14.8a.654.654 0 0 1 0-.96L6.92 7.5.21 1.16a.654.654 0 0 1 0-.96c.28-.27.74-.27 1.02 0l7.22 6.82c.28.27.28.7 0 .96L1.23 14.8c-.14.13-.33.2-.51.2s-.37-.07-.51-.2Zm5.05 0a.654.654 0 0 1 0-.96l6.71-6.34-6.7-6.34a.654.654 0 0 1 0-.96c.28-.27.74-.27 1.02 0l7.22 6.82c.28.27.28.7 0 .96L6.29 14.8c-.14.13-.33.2-.51.2s-.37-.07-.51-.2Z' style='fill:%23575757'/%3E%3C/svg%3E");
			background-size: cover;
			height: 15px;
			width: 15px;
			display: inline-block;
		}
	}
	img {
		width: 100%;
		margin-bottom: auto;
		margin-top: auto;
	}
	.bottom-text {
		font-family: var(--ff-nato);
		font-size: var(--fs-16);
		color: var(--gray300);
		line-height: var(--lh-22);
		text-align: center;
	}
}
