@import 'scss/mixins.scss';

.tab {
	@include mobile {
		transform: translateY(-100px);
	}
	&-buttons {
		border-bottom: 2px solid var(--white);
		// max-width: 1440px;
		max-width: 1200px;
		margin: 0 auto;
		display: flex;
		@include mobile {
			overflow: scroll;
			padding: 0 20px;
			border-bottom: 0;
			-ms-overflow-style: none;
			scrollbar-width: none;
			&::-webkit-scrollbar {
				display: none;
			}
		}
	}
	&-content {
		padding-bottom: 100px;
		padding-top: 100px;
		display: none;
		@include mobile {
			padding-bottom: 80px;
			padding-top: 80px;
		}
		&.active {
			display: block;
		}
	}
	button.tab-button {
		background-color: transparent;
		border: 0;
		font-family: var(--ff-unison-light);
		padding: 30px;
		cursor: pointer;
		position: relative;
		color: var(--white);
		flex: 1 1 0px;
		opacity: 0.5;
		@include mobile {
			font-size: var(--fs-12);
			padding: 15px 10px;
			white-space: nowrap;
		}
		&::after {
			background-color: var(--white);
			height: 5px;
			width: 100%;
			content: '';
			bottom: 0;
			left: 0;
			position: absolute;
			opacity: 0;
			@include mobile {
				height: 2.5px;
			}
		}
		&.active {
			opacity: 1;
			&::after {
				opacity: 1;
			}
		}
		&[disabled] {
			cursor: not-allowed;
		}
	}
}
