@import 'scss/mixins.scss';

.roadmap {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 313.92 2334.41'%3E%3Cpath d='M0 .51h275.38s36.12-1.61 37.45 37c1.33 38.61 0 2296.9 0 2296.9' style='fill:none;opacity:.5;stroke:%23c4c5c7'/%3E%3C/svg%3E");
	background-position: 45% 25px;
	background-repeat: no-repeat;
	margin-bottom: 120px;
	@include mobile {
		margin-bottom: 60px;
		background-image: unset;
	}
	h1 {
		margin-bottom: 40px;
		margin-top: 120px;
		@include mobile {
			margin-top: 60px;
			margin-bottom: 60px;
			text-align: center;
		}
	}
	&-items {
		@include mobile {
			display: none;
		}
	}
	&-items-slider {
		display: none;
		@include mobile {
			display: block;
		}
	}
	.pagination {
		margin-top: 40px;
		display: none;
		@include mobile {
			display: flex;
			justify-content: center;
		}
	}
}
