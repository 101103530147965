@import 'scss/mixins.scss';

.button {
	background-color: var(--pink);
	border: 0;
	border: 1px solid transparent;
	border-radius: var(--b-rad-5);
	max-height: 40px;
	font-family: var(--ff-ocra);
	font-size: var(--fs-14);
	padding: 10px 18px;
	cursor: pointer;
	display: flex;
	overflow: hidden;
	position: relative;
	z-index: var(--zi-5);
	color: var(--white);
	line-height: var(--lh-base);
	text-transform: uppercase;
	transition: all 0.3s ease-in-out;
	align-items: center;
	user-select: none;
	&::before,
	&::after {
		box-shadow: 2px 0px 14px transparent;
		height: 100%;
		width: 0%;
		content: '';
		display: block;
		left: -10%;
		position: absolute;
		top: 0;
		transform: skewX(-20deg);
	}
	&::before {
		background-color: var(--pink);
		z-index: var(--zi--5);
		transition: all 0.7s cubic-bezier(0.77, 0, 0.175, 1);
		opacity: 1;
	}
	&::after {
		background-color: var(--white);
		z-index: var(--zi--10);
		transition: all 0.4s cubic-bezier(0.2, 0.95, 0.57, 0.99);
		opacity: 0;
	}
	&:hover {
		border-color: var(--pink);
		&::before {
			width: 116%;
			opacity: 1;
		}
		&::after {
			width: 120%;
			opacity: 1;
		}
	}
	&[data-variant='outlined'] {
		background-color: transparent;
		border-color: var(--pink);
	}
	&[data-variant='white'] {
		background-color: var(--white);
		border-color: var(--white);
		color: var(--pink);
		&:hover {
			color: var(--white);
		}
	}
	&[data-variant='primary-outlined'] {
		border-color: var(--white);
	}
	&[data-variant='success'] {
		background-color: var(--success);
		border-color: var(--success);
		&::before {
			background-color: var(--success);
			border-color: var(--success);
		}
	}

	&[disabled],
	&[data-readonly='true'] {
		cursor: not-allowed;
		&:hover {
			&::before,
			&::after {
				display: none;
			}
		}
	}
	&[disabled] {
		background-color: var(--gray100);
		border-color: var(--gray100);
		color: var(--white);
	}
	&[data-readonly='true'] {
		background-color: transparent;
		border-color: var(--gray100);
		color: var(--gray100);
	}
	&[data-size],
	&[data-full-width='true'] {
		justify-content: center;
	}
	&[data-size='medium'] {
		min-width: 140px;
	}
	&[data-size='big'] {
		min-width: 300px;
	}
	&[data-full-width='true'] {
		width: 100%;
	}
}
