@import 'scss/mixins.scss';

.statistics-card {
	background-image: url('../../../../assets/img/the-bull-club/statistics-card-bg.png');
	background-size: cover;
	border-radius: var(--b-rad-15);
	min-height: 300px;
	min-width: 200px;
	padding: 20px;
	display: flex;
	position: relative;
	text-align: center;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	@include mobile {
		min-width: unset;
		min-height: 164px;
	}
	strong.stroked-text {
		font-size: var(--fs-36);
		margin-bottom: 15px;
		display: block;
		@include mobile {
			font-size: var(--fs-30);
			margin-bottom: 10px;
		}
	}
	em {
		font-family: var(--ff-unison-bold);
		font-size: var(--fs-10);
		line-height: 1.6;
		max-width: 100px;
	}
	&.main-card {
		background: transparent linear-gradient(352deg, #ff006c 0%, #f40067 72%, #a50046 100%) 0% 0% no-repeat padding-box;
		width: 360px;
		@include mobile {
			max-width: 360px;
			width: unset;
			display: block;
		}
		img {
			border-radius: var(--b-rad-15);
			box-shadow: 1px 1px 7px 7px #00000050;
			width: 100%;
			@include mobile {
				box-shadow: unset;
			}
		}
		.main-card-header {
			width: 100%;
			margin-top: 20px;
			display: flex;
			justify-content: space-between;
			span {
				font-family: var(--ff-anek);
				font-weight: var(--fw-600);
			}
			em {
				font-family: var(--ff-unison-light);
				opacity: 0.6;
			}
		}
		.card-actions {
			width: 100%;
			margin-bottom: 20px;
			margin-top: 20px;
			display: flex;
			gap: 10px;
			[data-variant='primary-outlined'] {
				flex-grow: 2;
				justify-content: center;
			}
		}
		p {
			font-family: var(--ff-nato);
			font-size: var(--fs-14);
			line-height: var(--lh-22);
		}
	}
	&[data-order='1'] {
		order: 1;
	}
	&[data-order='2'] {
		order: 2;
	}
	&[data-order='3'] {
		order: 3;
	}
	&[data-order='4'] {
		order: 4;
	}
	&[data-order='5'] {
		order: 5;
	}
}
