@import 'scss/mixins.scss';

.the-bull-portal {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 80px;
	& > .section-heading {
		font-size: 50px;
		@include mobile {
			font-size: 34px;
		}
	}
	.the-bull-portal-background {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		background-image: url('../../assets/img/portal-bg.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		filter: blur(15px);
	}
	button.button {
		@include mobile {
			max-height: unset;
			line-height: 1.4;
		}
	}
	.section-heading {
		@include mobile {
			line-height: 1.4;
			text-align: center;
		}
	}
	.connection-buttons {
		display: flex;
		gap: 55px;
		margin: 40px 0;
		button {
			justify-content: center;
			width: 270px;
			height: 38px;
			max-height: 43px;
			font-size: 16px;
		}
		@include mobile {
			align-items: center;
			gap: 30px;
			flex-direction: column;
			width: 100%;
			margin: 40px 0 50px;
			button {
				justify-content: center;
			}
		}
	}
	img.portal {
		margin-top: 40px;
		margin-bottom: 45px;
		object-fit: cover;
		@include mobile {
			width: 260px;
			height: 260px;
			transform: unset;
			margin-bottom: 30px;
		}
	}
	.action-button-wrapper {
		button {
			padding: 17.5px 40px;
			font-size: 18px;
			@include mobile {
				padding: 10px 25px;
				font-size: 14px;
			}
		}
	}
	h2 {
		max-width: 650px;
		margin-top: 2em;
		color: var(--anis);
		font-family: var(--ff-unison-bold);
		font-size: var(--fs-16);
		line-height: var(--lh-primary);
		text-align: center;
	}

	.port-stats {
		display: flex;
		align-items: center;
		.port-stats-items {
			display: flex;
			gap: 80px;
			flex-direction: column;
			@include mobile {
				gap: 50px;
			}
		}
		.port-stats-item {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 250px;
			height: 70px;
			padding-left: 60px;
			padding-right: 15px;
			background: transparent linear-gradient(180deg, #3b3b3b 0%, #808080 100%) 0% 0% no-repeat padding-box;
			border-radius: 8px;
			box-shadow: 0px 1px 7px #00000081;
			font-family: var(--ff-ocra);
			font-size: var(--fs-20);
			transform: translateX(15px);
			text-transform: uppercase;
			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 1em;
				width: 158px;
				height: 158px;
				background-repeat: no-repeat;
				background-size: contain;
				transform: translate(-60%, -50%);
				@include mobile {
					left: -1em;
					width: 75px;
					height: 75px;
					font-size: var(--fs-12);
				}
			}
			&.bulls {
				&::before {
					background-image: url('../../assets/img/bulls/chrome-bull.webp');
				}
			}
			&.tesseracts {
				&::before {
					background-image: url('../../assets/img/tesseracts/tesseract.webp');
				}
			}
			@include mobile {
				width: 130px;
				height: 36px;
				padding: 10px;
				font-size: 10px;
			}
		}
		@include mobile {
			gap: 40px;
			flex-direction: column;
		}
	}
	.end-message {
		margin-bottom: 3.2em;
	}
	@include mobile {
		flex-direction: column;
		padding: 60px 40px;
	}
}
