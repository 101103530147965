@import 'scss/mixins.scss';

.protocol-logos {
	padding-bottom: 120px;
	@include mobile {
		padding-top: 60px;
	}
	&-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		gap: 60px;
		@include mobile {
			gap: 30px;
		}
	}
	img {
		height: 158px;
		width: 350px;
		object-fit: contain;
		@include mobile {
			height: unset;
			max-height: 60px;
			max-width: 100px;
			width: unset;
		}
	}
}
