@import 'mixins';

.blur-background {
	background: none;
	border-top-left-radius: 35px;
	border-top-right-radius: 35px;
	box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
	margin-bottom: -600px;
	overflow: hidden;
	position: relative;
	z-index: var(--zi-1);
	transform: translateY(-345px);
	@include mobile {
		margin-bottom: 0;
		transform: translateY(-33px);
	}
	@media screen and (min-width: 769px) and (max-width: 1515px) {
		transform: translateY(-470px);
	}
}

.search-filters-wrapper {
	margin-bottom: 25px;
	margin-top: 50px;
	display: flex;
	align-items: center;
	gap: 10px;
	@include mobile {
		margin-bottom: 40px;
		margin-top: 40px;
	}
}
.filters-button {
	background-color: transparent;
	background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath fill='white' d='M28 9H11a1 1 0 0 1 0-2h17a1 1 0 0 1 0 2ZM7 9H4a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2ZM21 17H4a1 1 0 0 1 0-2h17a1 1 0 0 1 0 2ZM11 25H4a1 1 0 0 1 0-2h7a1 1 0 0 1 0 2Z'/%3E%3Cpath fill='white' d='M9 11a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1ZM23 19a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1ZM13 27a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1Z'/%3E%3Cpath fill='white' d='M28 17h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2ZM28 25H15a1 1 0 0 1 0-2h13a1 1 0 0 1 0 2Z'/%3E%3C/g%3E%3Cpath style='fill:none' d='M0 0h32v32H0z'/%3E%3C/svg%3E");
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	border: 0;
	height: 30px;
	width: 30px;
	padding: 0;
	display: none;
	@include mobile {
		display: block;
	}
	&.active {
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
	}
}

.filter-dropdown.sort-by {
	margin-bottom: 15px;
}

.trait-type-filters {
	@include mobile {
		background-color: rgba(17, 17, 17, 0.9);
		width: 100%;
		position: absolute;
		visibility: hidden;
		z-index: var(--zi-filter-menu);
		transition: all 0.3s ease-in-out;
		opacity: 0;
		backdrop-filter: blur(10px);
		&.active {
			visibility: visible;
			opacity: 1;
		}
		.connect-wallet-button {
			margin-bottom: 30px;
			margin-top: 30px;
			display: inline-block;
		}
	}
	.connect-wallet-button {
		display: none;
	}
}

.load-more-button {
	margin: 40px auto 0;
}
